.snackbar {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
}
.snackbar--error {
    background-color: #d9f0fa;
}
.snackbar--error .snackbar-icon-frame-wrapper {
    background-color: #d9f0fa;
}
.snackbar--success {
    background-color: #d9f0fa;
}
.snackbar--success .snackbar-icon-frame-wrapper {
    background-color: #d9f0fa;
}
.snackbar--info {
    background-color: #d9f0fa;
}
.snackbar--info .snackbar-icon-frame-wrapper {
    background-color: #d9f0fa;
}
.snackbar--warning {
    background-color: #d9f0fa;
}
.snackbar--warning .snackbar-icon-frame-wrapper {
    background-color: #d9f0fa;
}

.snackbar-container {
    width: auto;
    height: 54px;
    position: absolute;
    bottom: 20px;
    left: 50%;
    z-index: 100000;
    animation: fromBottom 1s forwards;
}
@media (max-width: 768px) {
    .snackbar-container {
        width: 100%;
        max-width: 300px;
   }
}
@keyframes fromBottom {
    0% {
        bottom: -100%;
   }
    70% {
        bottom: 30px;
   }
    100% {
        bottom: 20px;
   }
}
.snackbar-content-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.snackbar-content-wrapper .snackbar-background {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: -2%;
    left: 0;
}
.snackbar-content-wrapper .snackbar-background svg {
    border-bottom-left-radius: 14px;
}
.snackbar-content-wrapper .snackbar-content {
    position: relative;
    word-break: break-word;
    width: 100%;
    height: 100%;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.snackbar-content-wrapper .snackbar-content .snackbar-content-onclose {
    margin-top: 23px;
    margin-right: 12px;
    position: absolute;
    right: 0;
    top: 0;
    display: inline-flex;
    cursor: pointer;
}
.snackbar-content-wrapper .snackbar-content .d-descriptionUI {
    color: #000;
}
.snackbar-content-wrapper .snackbar-content .d-descriptionUI a {
    text-decoration: underline;
    font-weight: 700;
    color: #000;
}
.snackbar-icon-frame-wrapper {
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 width: 24px;
	 height: 24px;
	 border-radius: 50%;
	 z-index: 1;
	 position: relative;
	 margin-right: 20px;
}
 .snackbar-icon-frame {
	 position: relative;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 width: 100%;
	 height: 100%;
}
 .snackbar-icon {
	 display: flex;
	 justify-content: center;
	 width: 20px;
	 height: 20px;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #3B457B !important;
}

* {
  scroll-behavior: smooth;
}
.link a{
  font-weight: 500;
}

.loader {
  width: 64px;
  height: 64px;
  border: 5px solid #FFF;
  border-bottom-color: #4251a8;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  z-index: 200;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 
  input[type="radio"]{
    appearance: none;
    border: 1px solid #d3d3d3;
    width: 15px;
    height: 16px;
    content: none;
    outline: none;
    margin: 0;
    position: relative;
 }
 
 input[type="radio"]:checked {
   appearance: none;
   outline: none;
   padding: 0;
   content: none;
   position: relative;
   background: #0075FFFF;
 }
 
 input[type="radio"]:checked::before{
   position: absolute;
   color: white;
   content: "\00A0\2713\00A0" !important;
   font-weight: bolder;
   font-size: 10px;
 }